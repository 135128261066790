import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FetchApi from "../config/ApiCal";
import { imageUrl, stingsTxt } from "../config/Const";
import "../styles/Service.css";

// import { TiHomeOutline } from "react-icons/ti";
const Service = () => {
  const [service, setservice] = useState([]);
  const [bookLoader, setbookLoader] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    FetchApi("Categories")
      .then((e) => {
        setservice(e.data ?? []);
        setbookLoader(false);
        // console.log(e.data, "result");
      })
      .catch((e) => toast.error(e));
  }, []);

  const handleService = (id) => {
    toast.dismiss();
    let user = localStorage.getItem("user_id");
    if (user && user != null) {
      navigate("booking", {
        state: id,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      toast.error("Login to booking", {
        progress: undefined
      });
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div style={{ height: "70vh", overflow:"auto"}}>
      <ToastContainer />

      <Backdrop
        style={{
          color: "#fff",
          zIndex: 999,
        }}
        open={bookLoader}
        className=""
      >
        {/* <CircularProgress /> */}
        <div>
          <div
            className="d-flex align-items-center gap-3 p-3 px-5 text-white letter-space"
            style={{
              background: "rgba( 131, 43, 103, 0.55 )",
              boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.3 )",
              backdropFilter: "blur( 20px )",
              // "-webkit-backdrop-filter": "blur( 20px )",
              borderRadius: "10px",
              border: "1px solid rgba( 255, 255, 255, 0.3 )",
              height: 100,
            }}
          >
            <CircularProgress
              style={{
                color: "#2a2f83",
              }}
            />{" "}
            Loading
          </div>
        </div>
      </Backdrop>
      <div className="serive-play-ground">
        <div className="service-box">
          <span className="home-service-txt service-box-hd-txt">
            {stingsTxt.security} <span>{stingsTxt.service}</span>
          </span>
          <span className="service-box-desc">
            Select the service you need below.
          </span>
          <div className="service-box-play">
            {service.map((val, i) => {
              return (
                <div
                  className="service-box-play-card"
                  key={i + "service"}
                  onClick={() => handleService(val.id)}
                >
                  <img
                    src={imageUrl + val.category_image}
                    alt="subcategory image"
                    className="service-box-play-card-img"
                  />
                  <span className="service-box-play-card-txt text-capitalize ">
                    {val.category_name}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
    // <>
    //   <div className="container bg-white rounded-2 mt-5 d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
    //     <div className=" text-center">
    //       <img src={rocket} height='250px' />
    //       <div className="content">
    //         <h1 className='base-color mb-3'>We're  Coming <span className='app-color'> Soon !!</span></h1>
    //         <a href="/" className="bg-app-color rounded-2 text-decoration-none text-white p-2 mt-5" >
    //           {/* <TiHomeOutline className='mb-1' />  */}
    //           Back to Home
    //         </a>
    //       </div>{" "}

    //     </div>{" "}

    //   </div>{" "}

    // </>

  );
};

export default Service;
