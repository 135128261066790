export const stingsTxt = {
  security: "Security",
  service: "Service",
  service_desc: [
    "Results Driven",
    "Increased Conviction Rate",
    "Customer satisfaction",
    "Guaranteed Results",
  ],
  anti_social_behaviour: "Anti-Social Behaviour",
  be_your_voice: "Be Your Voice / protect and Support",
  how_insta_guard_help: "Here's how Instaguard can help.",
  automate: "Let Insta-guard be your Voice - To Help You and Support You in Times Of Need",
  let: "Let",
  help_you_today: "help you today !",
  app_name: "Instaguard",
  book_a_consultation: `“ Book a consultation with one of our experts.`,
  book_a_consult: `Book a Guard Today`,
  about_us: "About Us",
  about: "About",
  us: "Us",
};

// export const baseUrl = "https://instaguard.uplogictech.com/api/v2/";
// export const imageUrl = "https://instaguard.uplogictech.com/";
// export const baseUrl = "https://backend.insta-guard.co.uk/api/v2/";
// export const imageUrl = "https://backend.insta-guard.co.uk/uploads/";
// export const FACEBOOK_APP_ID = "1554353625396400";
// // export const FACEBOOK_APP_ID = "1037176944103373";//demo
// export const LINKEDIN_CLIENT_ID = "783atjn6kb8dhi";
// export const LINKEDIN_CLIENT_SECRET = "tRpTDLCNQhkSUzqb";
// // export const LINKEDIN_REDIRECT_URL =
// //   "https://insta-guardweb.uplogictech.in/login"; //demo 
// export const LINKEDIN_REDIRECT_URL = "https://www.insta-guard.co.uk/login";
// // export const GOOGLE_CLIENT_ID = "729845483920-pufoijaj0rmcugrodhrivhbsk69edhan.apps.googleusercontent.com"; //demo
// export const GOOGLE_CLIENT_ID = "729845483920-n96er35l593brdle1a459vnfkfv870p1.apps.googleusercontent.com";
// // export const PAYPAL_CLIENT_ID = "AW-fQ3fubsZ9wE1wbQ4rYGUgZXHNTUGG9zVLS9TIeSqPR9BpAwwN4JgV10D-21HHGzpjSVvBoI5JWxA5"; // live test
// export const PAYPAL_CLIENT_ID = "AY8oPtnKwakQFYIOoVIF6AUuaekP-km2fOslrpvgm88i1tyu3S--q2HqD5JdqaHbTbEXhDWElT8QjbeP";
// // export const PAYPAL_CLIENT_ID = "AYdK2krWwvxK1bPHieQfntCTznmxrDC4-vR5plHTluzS9azWpiNQYvQhcuJAK32SXCwdtPCnZXItSh6y"; // demo
// // export const STRIPE_CLIENT_ID = "pk_test_WDP0fwVbtyJzXdGucvd901EJ";
// // export const STRIPE_CLIENT_SECRET = "sk_test_RXLun9UnNrgStAK3sCHzcijK";
// // export const STRIPE_CLIENT_ID = "pk_test_51P1rAnLLAuey5bcic2N76H3pIRU4yAxc6EjvNmZ7Y4f0XfsglupZ8toI7WSqjvWIKQIycZMP48937ce82EjvjUxp00IJAj3XkR"; // live test
// // export const STRIPE_CLIENT_SECRET = "sk_test_51P1rAnLLAuey5bciBt9NlyFrwJc04OCXWtOBhqXyt1Q4s9OIUEsLJurKz3L20kN1W1GhsQLt5TgrR4RoSgOQSspA00VmlU2Q0c"; // live test
// export const STRIPE_CLIENT_ID = "pk_live_51P1rAnLLAuey5bci1vRIjqosLhzOFuraJmDocAEEfmg7S6WFRcoh8isNdDMnMtWMVUzbUmnaCx1coDnolVBo4ND700IEK6obnb";
// export const STRIPE_CLIENT_SECRET = "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";


//demo
export const baseUrl = "https://instaguard.uplogictech.com/api/v3/";
export const imageUrl = "https://instaguard.uplogictech.com/uploads/";
export const FACEBOOK_APP_ID = "1037176944103373";//demo
export const LINKEDIN_CLIENT_ID = "783atjn6kb8dhi";
export const LINKEDIN_CLIENT_SECRET = "tRpTDLCNQhkSUzqb";
export const LINKEDIN_REDIRECT_URL =
  "https://insta-guardweb.uplogictech.in/login"; //demo 
export const GOOGLE_CLIENT_ID = "729845483920-pufoijaj0rmcugrodhrivhbsk69edhan.apps.googleusercontent.com"; //demo
export const PAYPAL_CLIENT_ID = "AYdK2krWwvxK1bPHieQfntCTznmxrDC4-vR5plHTluzS9azWpiNQYvQhcuJAK32SXCwdtPCnZXItSh6y"; // demo
export const STRIPE_CLIENT_ID = "pk_test_51P1rAnLLAuey5bcic2N76H3pIRU4yAxc6EjvNmZ7Y4f0XfsglupZ8toI7WSqjvWIKQIycZMP48937ce82EjvjUxp00IJAj3XkR";
export const STRIPE_CLIENT_SECRET = "sk_test_51P1rAnLLAuey5bciBt9NlyFrwJc04OCXWtOBhqXyt1Q4s9OIUEsLJurKz3L20kN1W1GhsQLt5TgrR4RoSgOQSspA00VmlU2Q0c";

export function getStatusInfo(status) {
  let statusInfo = {
    name: "",
    bgColor: "",
    externalColor: "",
  };

  switch (status) {
    case 2:
      statusInfo = {
        name: "Accept",
        bgColor: "info",
        externalColor: "#3498db",
      };
      break;
    case 3:
      statusInfo = {
        name: "Start",
        bgColor: "warning",
        externalColor: "#f39c12",
      };
      break;
    case 4:
      statusInfo = {
        name: "Arrival",
        bgColor: "danger",
        externalColor: "#e74c3c",
      };
      break;
    case 5:
      statusInfo = { name: "Begin", bgColor: "info", externalColor: "#3498db" };
      break;
    case 6:
      statusInfo = {
        name: "Complete",
        bgColor: "success",
        externalColor: "#2ecc71",
      };
      break;
    case 7:
      statusInfo = {
        name: "Payment Done",
        bgColor: "success",
        externalColor: "#2ecc71",
      };
      break;
    case 8:
      statusInfo = {
        name: "Customer Cancel",
        bgColor: "warning",
        externalColor: "#e74c3c",
      };
      break;
    case 13:
      statusInfo = {
        name: "Schedule Job Accept",
        bgColor: "warning",
        externalColor: "#3498db",
      };
      break;
    case 1:
      statusInfo = {
        name: "Job in Request",
        bgColor: "warning",
        externalColor: "#2ecc71",
      };
      break;
    default:
      statusInfo = {
        name: "Guard Cancel",
        bgColor: "danger",
        externalColor: "#e74c3c",
      };
  }

  return statusInfo;
}
